//
// ----------------------- Server address -----------------------
//

//Local
// export const BASE_URL = "http://localhost:8083/";

//Local Lan
// export const BASE_URL = "http://10.10.3.25:8083/";

//Xmplify server
// export const BASE_URL = "http://192.168.2.65:8083/";

//Xmplify server exposed
// export const BASE_URL = "http://122.170.119.98:8083/";

//Buy policy now client server
// export const BASE_URL = "http://13.233.73.39:80/";
// export const BASE_URL = "http://65.1.5.85:80/";

// SKYDDA client server
export const BASE_URL = "https://staging.trampolin.io/";
// export const BASE_URL = "http://6134-43-250-165-132.ngrok.io/";

export const SERVER_ADDR = `${BASE_URL}api/`;

export const SUCCESS_REDIRECT = "https://trampolin:xoh7Iev1mah3hieCh2ongaez8oozoh7ee2ooterooTu7gi9aeGohvohy9phoojah@staging-pay.jumia.com.ng/services/trampolin?status=success&token=";


